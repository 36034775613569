@keyframes scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.marquee {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  color: white;
}

.marquee-content {
  display: flex;
  justify-content: space-between;
  padding-left: 100%;
  animation: scroll 20s linear infinite;
}
